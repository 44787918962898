.section {
  flex-grow: 1;
  min-width: 0;
}

.list {
  display: flex;
  min-width: 0;
  flex-grow: 1;
  margin-top: var(--unit_2);
}

.column {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  min-width: 0;
  flex-grow: 1;
  max-width: 33%;
}

.column:last-child {
  margin-right: 0;
}
