.section {
  margin: 0 auto 16px;
  padding: 0 20px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  align-items: center;
}

.container {
  display: grid;
  gap: 20px;
}
