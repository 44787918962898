@import '@cian/ui-kit/typography/variables.module.css';

.label {
  display: inline-flex;
  padding: 1px 8px;
  border-radius: 2px;
  font-size: var(--fontSize_12px);
  line-height: var(--lineHeight_18px);
  color: var(--gray60_100);
  background: var(--gray6_100);
}

.xs {
  font-size: var(--fontSize_10px);
  line-height: var(--lineHeight_14px);
  letter-spacing: 1px;
}

.warning {
  color: var(--accent-warning-primary);
  background: var(--surface-warning-default);
}

.success {
  color: var(--accent-positive-primary);
  background: var(--surface-positive-default);
}

.gray {
  color: var(--decorative-theme-dark);
  background: var(--gray6_100);
}

.error {
  color: var(--accent-negative-primary);
  background: var(--surface-negative-default);
}
