.container {
  position: relative;
  min-width: 122px;
}

.popup {
  margin-top: 6px;
  overflow-y: auto;
  min-width: 100%;
  max-width: 650px;
  max-height: 360px;
}

.nothingFound {
  padding: 10px;
}

.dropdown {
  margin: 4px 0;
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  /* stylelint-disable-next-line cian/no-custom-colors */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: column nowrap;
  max-height: 360px;
  overflow: auto;
  max-width: 600px;
  min-width: 100%;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &-container {
    padding: 5px 0;
  }

  &-group {
    margin: 0;

    &Title {
      padding-top: 10px;
      padding-left: 16px;
      padding-bottom: 10px;
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  &-item {
    position: relative;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    color: #121212;

    &--selected {
      background-color: #eaf3fc;
    }

    &--nested &Container {
      padding-left: 32px;
    }

    &--linked {
      padding-right: 44px;
    }

    &Container {
      padding-right: 10px;
      padding-left: 16px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &Desc {
      margin: 0;
      margin-top: -0.5em;
      padding-bottom: 0.5em;
      font-size: 14px;
      line-height: 1;
      color: #969696;
    }

    &Link {
      position: absolute;
      top: 6px;
      right: 6px;
      display: inline-block;
      width: 32px;
      height: 32px;
      background: url('./link.svg') 50% no-repeat;
      border-radius: 32px;

      &:hover {
        background-color: #cce2f7;
      }
    }
  }
}
