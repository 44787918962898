.container {
  height: 182px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  border-radius: 8px;
  margin: var(--unit_6) 0;
}

.content {
  padding: var(--unit_6) var(--unit_4) var(--unit_6) var(--unit_10);
}

.image {
  background-image: url('./assets/image.png');
  width: 424px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.title {
  margin-bottom: var(--unit_2);
}

.description {
  margin-bottom: var(--unit_7);
}
