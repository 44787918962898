.inputs {
  max-width: 160px;
}

.inputs,
.before,
.after {
  display: inline-block;
}

.before {
  padding-right: 5px;
}

.after {
  padding-left: 5px;
}
