/* при наведении на секцию карточки анимируем тень самой карточки */
.cont:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1;
}

/* при наведении на секцию карточки анимируем появление кнопок */
.cont:hover .controls {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

/* при наведении на секцию карточки анимируем появление кнопок галереи */
.cont:hover .gallery-arrow {
  transform: translate(0, -50%);
  opacity: 1;
}
