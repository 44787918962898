.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: var(--black_60);
}

.label + .label {
  margin-left: 4px;
}
