.container {
  margin: 26px 0;
  overflow: hidden;
  border-radius: 4px;
  background-image:
    url('./assets/deals.png'), url('./assets/lines.png'),
    linear-gradient(
      230deg,
      #034035 -20.5%,
      #006cfd 6.22%,
      #1e7afd 9.21%,
      #bec3fe 25.72%,
      #ffe1ff 44.34%,
      #ffdcc8 59.51%,
      #ffebaf 86.24%,
      #fff 111.46%
    );
  background-size: clamp(350px, 30vw, 390px), min(710px, 62%), 100%;
  background-position:
    91% 45%,
    115% center,
    center;
  background-repeat: no-repeat, no-repeat, no-repeat;

  @media (max-width: 1050px) {
    background-position:
      right 45%,
      115% center,
      center;
  }

  @media (max-width: 900px) {
    border-radius: 8px;
    background-image:
      url('./assets/deals-mob.png'), url('./assets/lines-mob.png'),
      linear-gradient(
        338.23deg,
        #034035 -11.17%,
        #006cfd -3.8%,
        #1e7afd 5.13%,
        #bec3fe 31.79%,
        #ffe1ff 49.63%,
        #ffdcc8 63.85%,
        #ffebaf 88.91%,
        #fff 112.57%
      );
    background-size: 160px, 250px, 100%;
    background-position:
      right 80%,
      right bottom,
      center;
  }
}

.wrapper {
  padding: 32px 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  text-decoration: none;
  color: var(--decorative-theme-dark);
  cursor: default;

  @media (max-width: 900px) {
    padding: 16px;
  }
}

.title {
  margin-bottom: 8px;
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;

  @media (max-width: 900px) {
    font-size: var(--fontSize_28px);
    line-height: var(--lineHeight_36px);
  }
}

.description {
  margin-bottom: 24px;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);

  @media (max-width: 1050px) {
    max-width: 400px;
  }

  @media (max-width: 900px) {
    font-size: var(--fontSize_14px);
    line-height: var(--lineHeight_20px);
  }

  @media (max-width: 600px) {
    max-width: 250px;
    margin-bottom: 76px;
    white-space: pre-line;
  }
}

.button {
  cursor: pointer;
}
