.empty-listing-banner {
  display: flex;
  align-items: center;
  column-gap: var(--unit_7);
  height: 124px;
  margin-top: var(--unit_6);

  &--image {
    background-image: url('./assets/image.svg');
    background-repeat: no-repeat;
  }

  &--text {
    display: flex;
    flex-direction: column;
    row-gap: var(--unit_2);
  }
}
