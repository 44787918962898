.container {
  position: relative;
  margin: 0 -20px;
  padding-bottom: 1px;
}

.container::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 20px;
  left: 20px;
  height: 1px;
  background-color: var(--gray6_100);
}

.card {
  display: flex;
  cursor: pointer;
  padding: 20px 20px 0;
  border-radius: 0;
  box-shadow: none;
  transition: box-shadow 200ms ease-in;
  /* костыль для того в карточку помещались все выводимые компоненты */
  /* stylelint-disable comment-word-disallowed-list */
  /* TODO: нужна проработка с дизайнером */
  min-height: 315px;
  max-height: 401px;
}

.colored {
  background-color: var(--surface-warning-default);
}

.media {
  width: 275px;
  display: flex;
  flex-shrink: 0;
  flex-flow: column nowrap;
  margin-right: 32px;
  position: relative;
  text-decoration: none;
}

.wide .media {
  width: 375px;
}

.card .content {
  display: flex;
  flex-grow: 1;
}

.card .general {
  display: flex;
  padding-right: 32px;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
}

.aside {
  flex: 0 1 288px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-flow: column nowrap;
}

.agent-cont {
  flex-grow: 1;
  display: flex;
}

.agent {
  display: flex;
  flex-grow: 1;
  padding-right: 16px;
  text-decoration: none;
}

.controls {
  width: 40px;
}

.vas {
  flex-shrink: 0;
}

.link {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  max-height: 100%;
  text-decoration: none;
}

.comments {
  margin-bottom: 20px;
}

.buttons {
  display: block;
}
