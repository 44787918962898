.container {
  margin-bottom: 25px;
  background-color: white;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 0;
  padding: 0 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0;
  color: #7a7a7a;
}
.title a {
  text-decoration: none;
  color: #7a7a7a;
}

.content {
  margin-top: 30px;
  display: flex;
}

.content-gallery {
  position: relative;
  margin-left: 40px;
  width: 400px;
  height: 280px;
  flex: 0 0 400px;
}

.content-title {
  margin-bottom: 10px;
}
.content-title > a {
  padding-bottom: 1px;
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition:
    color 0.14s,
    border-color 0.15s;
}

.content-title > a:hover {
  color: #256ba5;
  border-color: rgba(0, 122, 235, 0.3);
}

.content-info {
  margin-bottom: 3px;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #121212;
}

.content-main {
  margin-right: auto;
}

.content-address {
  position: relative;
  padding-left: 17px;
  display: inline-block;
}

.content-address::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 11px;
  height: 16px;
  content: '';
  background-image: url('./icon_address.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.content-description {
  margin-top: 17px;
  margin-bottom: 3px;
  max-height: 180px;
  overflow: hidden;
  position: relative;
  white-space: pre-wrap;
}

.content-description::after {
  content: ' ';
  position: absolute;
  top: 150px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

.content-description > p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: #121212;
}

.content-link > a {
  padding-bottom: 1px;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #2b87db;
  border-bottom: 1px solid transparent;
  transition:
    color 0.14s,
    border-color 0.15s;
}

.content-link > a:hover {
  color: #256ba5;
  border-color: rgba(0, 122, 235, 0.3);
}
