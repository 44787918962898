.container {
  position: relative;
  display: block;
  height: 294px;
  max-width: 253px;
  width: 100%;
  text-decoration: none;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
}

.link {
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
}

.container:not(:last-child) {
  margin-right: 16px;
}

.preview {
  position: relative;
  margin: -1px;
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--gray10_100);
  border-bottom: 0;
  padding-bottom: 28px;

  img {
    border-radius: 4px 4px 0 0;
  }
}

.price-group {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
}

/* stylelint-disable value-no-vendor-prefix */
.price {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 4px;
}

.builder {
  margin-bottom: 4px;
  color: var(--icon-secondary-default);
}

.content {
  padding: 8px 16px 16px;
}

.content > h5,
.builder > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.address > span {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  max-height: 40px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.link-newbuilding {
  position: absolute;
  top: 154px;
  left: 16px;
  text-decoration: none;
  color: var(--accent-main-primary);

  & > span {
    display: -webkit-box;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}
