.highlighted {
  background-color: #fff500;
  border-radius: 2px;
}

.price-container {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.strikethrough {
  font-size: var(--fontSize_14px);
  line-height: 20px;
  font-weight: normal;
  color: var(--text-secondary-default);
  text-decoration: line-through;
  text-decoration-color: var(--accent-positive-primary);
  text-decoration-thickness: 2px;
}
