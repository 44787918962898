.pessimization {
  padding: 14px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: white;
  background: #7a7a7a;
}

.pessimization.strong {
  background: #ed6d6d;
}
