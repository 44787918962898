.container {
  position: relative;
  padding: 4px 30px 4px 12px;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  pointer-events: auto;
  color: var(--decorative-theme-dark);
  background: var(--gray6_100);
  border-radius: 4px;
  user-select: none;
}

.button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  padding: 0 6px 0 0;
  width: 24px;
  height: 100%;
  cursor: pointer;
  color: inherit;
  background: none;
  border: none;
}
