.container {
  background-color: white;
  border-bottom: 1px solid var(--gray10_100);
}

.secondary-group {
  flex-grow: 1;
  min-width: 0;
}

.geo {
  width: 100%;
}

.button {
  margin-left: 12px;
  white-space: nowrap;
}

.layout {
  margin: 0 auto;
  padding: 20px 16px;
  min-width: 704px;
  max-width: 1376px;
}

.filters-layout {
  display: flex;
}

.tags-layout {
  margin-top: 12px;
}

@media (max-width: 1279px) {
  .filters-layout {
    flex-direction: column-reverse;
  }

  .button {
    margin-left: 6px;
  }
}
