.filters {
  position: relative;
  z-index: 9; /* Должен быть меньше чем у шапки */
}

.fixed .main-filters-container {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  transition: transform 0.5s ease 0s;
  backface-visibility: hidden;
}

.fixed.hidden .main-filters-container {
  transform: translate3d(0, -100%, 0);
}

.fixed.notransition .main-filters-container {
  transition: none;
}

.filters-container {
  position: relative;
  background-color: var(--decorative-theme-white);
}

.fixed .filters-container::before {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 8px;
  pointer-events: none;
  content: '';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.09), transparent);
}

.main-filters-container {
  z-index: 1;
}
