/*
 * Стили взяты из библиотеки swiper
 * Подключенные модули:
 *   * core
 *   * pagination
 *   * navigation
 */
/* stylelint-disable */
:global {
  :root {
    --swiper-theme-color: #007aff;
  }
  .swiper-container {
    position: relative;
    z-index: 1;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    overflow: hidden;
    list-style: none;
  }
  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  .swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    transition-property: transform;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }
  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .swiper-container-free-mode > .swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out;
  }
  .swiper-container-pointer-events {
    touch-action: pan-y;
  }
  .swiper-container-pointer-events.swiper-container-vertical {
    touch-action: pan-x;
  }
  .swiper-slide {
    position: relative;
    width: 100%;
    height: 100%;
    transition-property: transform;
    flex-shrink: 0;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
  .swiper-container-3d {
    perspective: 1200px;
  }
  .swiper-container-3d .swiper-cube-shadow,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-wrapper {
    transform-style: preserve-3d;
  }
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none;
  }
  .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
  }
  .swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
  .swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }

  .swiper-pagination {
    position: absolute;
    z-index: 10;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
  }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: 0;
    bottom: 10px;
    width: 100%;
  }
  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    position: relative;
    transform: scale(0.33);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: var(--decorative-theme-black);
    border-radius: 50%;
    opacity: 0.2;
  }
  button.swiper-pagination-bullet {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    appearance: none;
    appearance: none;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  .swiper-pagination-bullet:only-child {
    display: none !important;
  }
  .swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    opacity: 1;
  }
  .swiper-container-vertical > .swiper-pagination-bullets {
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0);
  }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
  }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    width: 8px;
    transform: translateY(-50%);
  }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition:
      0.2s transform,
      0.2s top;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    transition:
      0.2s transform,
      0.2s left;
  }
  .swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition:
      0.2s transform,
      0.2s right;
  }
  .swiper-pagination-progressbar {
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    transform: scale(0);
    transform-origin: left top;
  }
  .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
  .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
  .swiper-container-vertical > .swiper-pagination-progressbar {
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }
  .swiper-pagination-white {
    --swiper-pagination-color: var(--decorative-theme-white);
  }
  .swiper-pagination-black {
    --swiper-pagination-color: var(--decorative-theme-black);
  }
  .swiper-pagination-lock {
    display: none;
  }
  :root {
    --swiper-navigation-size: 44px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    display: flex;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    cursor: pointer;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.35;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    font-variant: initial;
    line-height: 1;
    letter-spacing: 0;
    text-transform: none !important;
    text-transform: none;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    right: auto;
    left: 10px;
  }
  .swiper-button-prev::after,
  .swiper-container-rtl .swiper-button-next::after {
    content: 'prev';
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }
  .swiper-button-next::after,
  .swiper-container-rtl .swiper-button-prev::after {
    content: 'next';
  }
  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: var(--decorative-theme-white);
  }
  .swiper-button-next.swiper-button-black,
  .swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: var(--decorative-theme-black);
  }
  .swiper-button-lock {
    display: none;
  }
}
