.container {
  display: block;
  width: 310px;
  background-color: var(--decorative-theme-black);
  background-image: url('./assets/image.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  border-radius: var(--unit_1);
  padding: var(--unit_2) var(--unit_4) var(--unit_3);
  text-decoration: none;
}

.subtitle {
  display: inline-block;
  margin-top: var(--unit_2);
  border-bottom: 1px solid var(--decorative-theme-white);
}
