.container {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  pointer-events: none;
}

.map-controls {
  display: flex;
  flex-direction: column;
}

.button {
  padding: 0;
  display: flex;
  min-width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  color: var(--accent-main-primary);
  background: white;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;

  &:hover {
    border-color: var(--gray10_100);
    background: var(--gray10_100);
  }
}

.button > * {
  display: block;
}

.zoom-out {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.zoom-in {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
