.offer-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.labels {
  display: flex;
  gap: 4px;
}

.row {
  margin-top: 8px;
  padding: 8px 40px;
  display: grid;
  width: 840px;
  min-height: 86px;
  cursor: pointer;
  grid-template-columns: auto 60px;
  column-gap: 20px;
}

.inner-row {
  all: unset;
  display: grid;
  cursor: pointer;
  grid-template-columns: auto 120px 112px 120px;
  column-gap: 20px;
}

.row:hover {
  background-color: #f3f6ff;
}

.row ~ .row {
  margin-top: 0;
}

.description-cell {
  display: flex;
  color: #707a95;
  align-items: center;
}

.description-cell span {
  width: 100%;
}

.controls-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
