.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actions {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.content {
  min-width: 550px;
}

.title {
  margin-bottom: 16px;
}

.error {
  max-width: 370px;
  background-color: var(--decorative-theme-dark);
  padding: 12px;
  position: absolute;
  bottom: calc(100% + 4px);
  border-radius: 4px;
  width: max-content;
}

.download_btn_wrapper {
  position: relative;
}
