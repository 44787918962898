.photo {
  min-height: 140px;
  height: 140px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.info {
  padding: 12px 14px 10px;
  color: var(--decorative-theme-black);
  height: 100%;
}

.price {
  margin: var(--unit_1) 0;
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 269px;
  background-color: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  flex: 1;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  cursor: pointer;
  overflow: hidden;
}

.link ~ .link {
  margin-left: var(--unit_4);
}

.link:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-bottom: none;
}

.link:last-child {
  margin-right: 0;
}

.location-container {
  display: flex;
  margin-top: var(--unit_3);
}

.highway-icon {
  margin-top: 2px;
  margin-right: var(--unit_2);
}
