/* stylelint-disable cian/no-custom-colors */
@import '@cian/ui-kit/typography/variables.module.css';

.option {
  position: relative;
  padding: 0 36px 0 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 36px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  outline: none;

  &.focused {
    background: var(--black_6);
  }

  &.multiple {
    padding-left: 38px;
  }
}

.icon,
.checkbox {
  position: absolute;
  cursor: pointer;
  top: 10px;
}

.icon {
  right: 12px;
}
.checkbox {
  top: 11px;
  left: 12px;
}

.marker {
  margin-left: 8px;
  padding: 2px 8px;
  font-size: var(--fontSize_12px);
  font-weight: bold;
  line-height: var(--lineHeight_16px);
  color: var(--decorative-theme-white);
  background: var(--accent-negative-primary);
  border-radius: 2px;
}

.checkbox input + span {
  margin-top: 0;
  top: 0;
}
