.title {
  margin-bottom: 32px;

  /* stylelint-disable value-no-vendor-prefix */
  & > span {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
}

.logo {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid var(--gray10_100);
  margin-bottom: 24px;
  background-color: var(--decorative-theme-white);
}
