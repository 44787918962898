.wrapper {
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.container {
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e4e4e4;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  padding: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

.newobjectcardGroup {
  margin-left: -8px;

  > a {
    margin-left: 8px;
  }
}

.newobjectcard {
  display: inline-block;
  width: calc(25% - 8px);
  text-decoration: none;
  cursor: pointer;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  transition: border-color 0.15s;

  &:hover {
    border-color: #c9c9c9;
  }

  &-img {
    position: relative;
    height: 200px;
    background-color: var(--decorative-subway-msk-serpukhovskaya);
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &-content {
    overflow: hidden;
    height: 107px;
    font-family: Lato, Arial, sans-serif;
  }

  &-footer {
    display: table;
    height: 32px;
    font-family: Lato, Arial, sans-serif;
  }

  &-title {
    display: block; /* Fallback for non-webkit */
    /* stylelint-disable-next-line */
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
    max-height: 42px; /* Fallback for non-webkit */
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &-status {
    font-size: 12px;
    white-space: nowrap;
    text-transform: uppercase;
    color: #8e8e8e;
  }

  &-price {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }
}

.underground {
  position: relative;
  padding: 6px 0 0 17px;
  font-size: 14px;
  line-height: 18px;

  &-pin {
    position: absolute;
    top: 12px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &-name {
    display: inline-block;
  }

  &-time {
    display: block;
    color: #8e8e8e;
  }
}

.footer {
  display: table-cell;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  vertical-align: middle;
  color: #8e8e8e;
}

.address {
  padding-top: 6px;
  font-size: 14px;
  line-height: 18px;
}

.card-footer--noborder {
  border-color: transparent;
}

.vertical_card_extended_layout {
  all: initial;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 200px;
  max-width: 330px;
  background: var(--decorative-theme-white);
  border-radius: 6px;

  &-media {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
  }

  &-content {
    padding: 16px 20px;
    box-sizing: border-box;
    width: 100%;
  }

  &-footer {
    padding: 8px 20px 11px;
    box-sizing: border-box;
    border-top: 1px solid #e8e8e8;
  }
}
