.wrapper {
  border-radius: 2px;
  border: solid 1px #e4e4e4;
  background-color: white;
  margin: 12px 0;
  padding: 16px 20px;
}

.header {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: black;
  margin-bottom: 4px;

  a {
    color: #0087e2;
    text-decoration: none;

    &:hover {
      color: #256ba5;
    }
  }
}

.text {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  display: flex;
  align-items: center;
}

.emoji {
  background-image: url('./assets/hands.png');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
