.kp {
  color: var(--accent-main-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.kp svg {
  margin-right: 4px;
}
