.banner {
  padding: 20px 24px 20px 0;
  display: flex;
  gap: 20px;
  position: relative;
  width: 100%;
  background-color: var(--surface-neutral-default);
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 1336px;
}

.image {
  width: 82px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
