.container {
  display: flex;
  justify-content: flex-end;
}

.container--dual-side {
  display: flex;
  justify-content: space-between;
}

.primary_buttons {
  display: flex;
}

.profsearch_link {
  color: var(--accent-main-primary);
  display: flex;
  text-decoration: none;
}

.profsearch_link img {
  margin-right: 8px;
  height: 28px;
}

.profsearch_link span {
  line-height: 28px;
}

.container button + button,
.container--dual-side button + button {
  margin-left: 8px;
}
