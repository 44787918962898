.container {
  margin: 0 auto;
  padding: 0 var(--unit_5, 20px) var(--unit_4, 16px);
  width: 100%;
  max-width: 1376px;

  &:empty {
    display: none;
  }
}
