.breadcrumbContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  align-items: center;
}

.breadcrumb {
  font-size: 14px;
  line-height: 1.29;
  cursor: pointer;
  color: #7a7a7a;

  &:hover {
    color: #0084ff;
  }
}

.endButton {
  position: relative;
  padding: 3px 28px 5px 12px;
  overflow: hidden;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    top: 8px;
    right: 12px;
    display: block;
    float: right;
    width: 8px;
    height: 8px;
    cursor: pointer;
    content: '';
    background: url('../assets/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.breadcrumbWrapper {
  display: flex;
  height: 24px;
  align-items: center;
}

.arrow {
  padding: 0 10px;
  width: 10px;
  height: 10px;
  background: url('../assets/arrow.svg');
  background-repeat: no-repeat;
  background-position: 50%;
}
