.timeLabel {
  min-width: 90px;
  height: 20px;
  text-align: right;
  &:hover {
    .relative {
      opacity: 0;
      transition: opacity 0.1s ease;
    }

    .absolute {
      opacity: 1;
      transition: opacity 0.1s ease 0.1s;
    }
  }
}

.relative,
.absolute {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.relative {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.1s ease 0.1s;
}

.absolute {
  opacity: 0;
  transition: opacity 0.1s ease;
  transform: translateY(-100%);
}
