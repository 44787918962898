.banner {
  height: 52px;
  width: 824px;
  background: #f1f2f4;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  margin: 16px auto 12px;
}

.notnow {
  height: 28px;
  padding: 4px 12px;
  cursor: pointer;
  margin-left: 8px;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.buttons {
  margin-left: auto;
  display: flex;
}
