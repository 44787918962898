.container {
  margin-bottom: 15px;
  background-color: white;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 0;
  padding: 25px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.breadcrumbs {
  margin-bottom: 20px;

  &-link {
    line-height: 20px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    color: #666;
    border: none;
    transition: color 0.15s;

    &:hover {
      color: black;
    }
  }

  &-delimiter {
    position: relative;
    margin: 0 10px;
    display: inline-block;
    width: 5px;
    height: 8px;
    background-image: url('../icon_arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0;
  color: #121212;

  a {
    text-decoration: none;
    color: #1a79d5;
  }
}

.content {
  margin-top: 30px;
  display: flex;

  &-logotype {
    margin-right: 30px;

    img {
      max-width: 120px;
      max-height: 120px;
    }
  }

  &-main {
    font-size: 14px;
    color: #666;
  }

  &-info {
    margin-bottom: 15px;
  }

  &-title {
    margin-bottom: 12px;

    a {
      padding-bottom: 1px;
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
      text-decoration: none;
      color: #2b87db;
      border-bottom: 1px solid transparent;
      transition:
        color 0.14s,
        border-color 0.15s;

      &:hover {
        color: #256ba5;
        border-color: rgba(0, 122, 235, 0.3);
      }
    }
  }

  &-projects {
    display: table-row;
  }

  &-projectsLabel {
    padding-right: 20px;
    display: table-cell;
  }

  &-projectsData {
    display: table-cell;
  }

  &-projectsList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding-bottom: 5px;

      a {
        font-weight: bold;
        text-decoration: none;
        color: #1a79d5;
      }
    }
  }

  &-description {
    margin-top: 15px;

    p {
      margin: 0;

      a {
        font-weight: bold;
        text-decoration: none;
        color: #1a79d5;
      }
    }
  }
}

@media print {
  .breadcrumbs {
    display: none;
  }
}
