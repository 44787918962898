.container {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 2;
  margin-top: 8px;
  padding: var(--unit_6);
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}
