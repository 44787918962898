.checkbox-wrapper {
  padding: 8px 12px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  column-gap: 8px;
  cursor: pointer;
}

.checkbox {
  display: flex;
  grid-column: 1;
  grid-row: 1;
}

.checkbox-label {
  overflow: hidden;
  grid-column: 2;
  grid-row: 1;
}

.checkbox-label-text {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-description {
  grid-column: 2 / 3;
  grid-row: 2;
}

.checkbox-adornment {
  padding-left: 8px;
  grid-column: 3;
  grid-row: 1;
}

.checkbox-wrapper {
  outline: none;
}

.checkbox-wrapper:hover,
.checkbox-wrapper:focus,
.checkbox-wrapper.focused {
  background-color: var(--black_6);
}
