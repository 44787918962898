.container {
  position: relative;
  margin: -24px -14px;
  padding: 24px 14px;
  overflow: hidden;

  & > div {
    overflow: unset;
  }
}

.wrapper {
  position: relative;
}
