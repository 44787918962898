.suggestion {
  color: var(--gray20_100);
}

.suggestion a {
  text-decoration: none;
  color: var(--gray20_100);
}

.suggestion a:hover {
  color: var(--accent-main-primary);
}
