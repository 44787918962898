.container {
  margin: 0 -2px;
  display: flex;
  flex-wrap: wrap;
}

.label {
  z-index: 1;
  margin: 0 2px 4px;
  line-height: var(--unit_4);
}
