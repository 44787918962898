.footer {
  max-width: 598px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding: 16px 24px;
  background-color: var(--accent-main-secondary);
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  text-decoration: none;
  color: inherit;
}
