.container {
  padding: 12px 40px;
  display: flex;
}

.label {
  flex: 0 0 140px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: right;
}

.content {
  margin-left: 40px;
}
