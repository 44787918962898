.more-button {
  display: block;
  box-sizing: border-box;
  font-weight: bold;
  line-height: 89px;
  text-decoration: none;
  cursor: pointer;
  color: #2b87db;
  background-color: white;
  border: solid 1px #e4e4e4;
}

.more-button:hover {
  color: #256ba5;
}

.preloader-container {
  padding: 27px;
  text-align: center;
}
