.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.title-container {
  margin-bottom: var(--unit_6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.button {
  color: var(--accent-main-primary);
  text-decoration: none;
}

.villages-list {
  display: flex;
  flex-direction: row;
}
