.container {
  position: absolute;
  z-index: 1;
  padding: 16px 16px 0;
  top: 0;
  left: 0;
}

.has-features {
  /* если на фото есть иконки фич "Объявление с видео" или "Объявление с 3d-туром", добавляем отступ, чтобы
  лейблы не заезжали на них */
  padding-right: 50px;
}

.label {
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 2px;
  padding: 0 8px 2px;
  color: var(--decorative-theme-white);
  margin-bottom: 8px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.icon {
  display: inline-block;
  margin-right: var(--unit_1);
  width: var(--unit_3);
  height: var(--unit_3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.orange-label {
  background: var(--accent-warning-primary);
}

.blue-label {
  background: var(--accent-main-primary);
}

.grey-label {
  background: var(--gray60_100);
}

.green-label {
  background: var(--accent-positive-primary);
}

.red-label {
  background: var(--accent-negative-primary);
}

.transparent-label {
  background: var(--overlay-default);
}

.lightgrey-label {
  background: #f1f2f4;
  color: var(--decorative-theme-dark);
}

.black-label {
  background: var(--decorative-theme-dark);
  color: var(--decorative-theme-white);
}
