.main {
  margin: 0;
  padding: 5px 16px 7px;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 14px;
  outline: 0;
  transition:
    background 0.1s,
    color 0.1s;

  &:active:not(:disabled) {
    transform: perspective(1px) scale(0.98);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.main--small {
  padding: 2px 15px 4px;
  border-radius: 14px;
}

.main--large {
  padding: 9px 22px;
  font-size: 20px;
  border-radius: 21px;
}

.main--xlarge {
  padding: 12.5px 29px;
  font-size: 26px;
  border-radius: 28px;
}

.outline {
  padding: 3px 15px 5px;
  font-size: 14px;
  line-height: 1.35;
  border: 1px solid transparent;
}

.outline--large {
  padding: 7px 22px;
  border-width: 2px;
}

.outline--xlarge {
  padding: 9.5px 29px;
  border-width: 3px;
}
