.pagination-section {
  margin: 64px 0;
}

.pagination {
  padding-bottom: 48px;
}

.pagination:empty {
  padding: 0;
}

.buttons {
  display: flex;
  justify-content: center;
}

.buttons button + button {
  margin-left: 24px;
}
