.wrapper {
  outline: none;
  text-decoration: none;
}

.container {
  cursor: default;
  margin: 24px 0;
  padding: 24px 40px;
  position: relative;
  color: var(--decorative-theme-dark);
  border-radius: 8px;
  font-family: Lato, Helvetica, sans-serif;
  background: url('./images/bg.svg') no-repeat center right;
  background-size: cover;
}

.logo {
  position: absolute;
  right: 40px;
  width: 175px;
  height: 28px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin: 0;
  margin-bottom: 8px;
  font-size: 38px;
  line-height: 46px;
  text-decoration: none;
}

.text {
  margin: 0;
  margin-bottom: 28px;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.button {
  cursor: pointer;
  margin: 0;
  padding: 9px 16px;
  background: var(--accent-main-primary);
  color: white;
  border: 1px solid var(--accent-main-primary);
  font-weight: bold;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
}

.button:hover {
  background: var(--control-main-primary-hovered);
}

@media (max-width: 880px) {
  .container {
    margin: 0;
    padding: 88px 16px 16px;
    border-radius: 0;
    background: url('./images/bg_small.svg') no-repeat center right;
    background-size: cover;
  }

  .logo {
    top: 32px;
    left: 15px;
    width: 150px;
    height: 24px;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }

  .text {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }

  .button {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .container {
    margin: 0;
  }
}
