.districts {
  margin: 0 32px;
  min-width: 952px;
  max-width: 1192px;
  min-height: 200px;
  max-height: calc(100vh - 120px);
  background-color: white;
}

.districtsModalMoscow {
  margin-top: 100px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
