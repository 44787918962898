.container {
  width: 385px;
  padding-right: 32px;
}

.container-vertical {
  width: 214px;
}

.title {
  padding-bottom: 8px;
}
