.video-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.preview {
  height: 100%;
}

.preview--blur {
  transform: scale(1.5);
  inset: 0;
  filter: blur(80px) saturate(1.3);
}
