.placeholder {
  color: var(--gray40_100);
  user-select: none;
}

.placeholder.placeholder-active {
  color: var(--decorative-theme-dark);
}

.placeholder::after {
  content: '\00a0';
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  user-select: none;
}

.placeholder,
.label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  flex: 0 0 auto;
  align-self: center;
  background: var(--gray6_100);
  border-radius: 4px;
  padding: 1px 6px;
  margin: -1px 0 -1px 18px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
