.popup-link {
  user-select: none;
  cursor: pointer;
  color: white;
  font-weight: normal;
  transition: color 0.15s;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}
