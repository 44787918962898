.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 148px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: var(--decorative-theme-white);
  text-decoration: none;
  margin: 0 auto;

  &::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    /* stylelint-disable-next-line */
    bottom: 0;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    content: '';
    z-index: 1;
  }
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 42px;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  flex: none;
  background-color: var(--decorative-theme-white);
}

.logo-image {
  background-size: contain;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.wrapper {
  position: relative;
  z-index: 1;
  padding: 0 40px;
  display: flex;
}

.content {
  margin-left: 40px;
  padding-top: 24px;
  width: 100%;
  display: flex;
}

.label {
  color: var(--white_60);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
}

.control {
  margin-top: 30px;
  margin-left: auto;
  flex: none;
}

/* stylelint-disable value-no-vendor-prefix */
.title,
.text {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
}

.note {
  position: relative;
  z-index: 1;
  margin-top: auto;
  padding-left: 16px;
  padding-bottom: 4px;
  color: var(--white_60);
  font-size: 12px;
  line-height: 16px;
}

.container.container--without-substrate {
  &::before {
    display: none;
  }
}

.container.container--without-substratelogo .logo {
  background-color: inherit;
}
