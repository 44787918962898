.container {
  padding: 16px 40px 40px;
  width: 760px;
}

.container-inner {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 32px;
}

.image {
  width: 335px;
  height: 213px;
  background: url('./assets/bird.svg');
  border-radius: 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
