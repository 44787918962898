.container {
  overflow: hidden;
  max-width: 100%;
  font-size: 12px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
}

.text {
  display: inline-block;
}
