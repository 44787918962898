.container {
  padding: 24px 32px 23px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: white;
}

.title {
  margin-bottom: 8px;
  line-height: 1.43;
  font-size: 14px;
  color: #121212;
}

.link-container {
  display: inline-block;
  margin-right: 32px;
  font-size: 14px;
  line-height: 1.43;
}
.link {
  padding-bottom: 1px;
  color: #2b87db;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition:
    color 0.14s,
    border-color 0.15s;
}

.link:hover {
  color: #256ba5;
  border-color: rgba(0, 122, 235, 0.3);
}

.counter {
  margin-left: 16px;
  color: #969696;
}

.dot {
  margin-right: 32px;
}
