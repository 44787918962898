.container {
  position: fixed;
  z-index: 100;
  bottom: 24px;
  right: 24px;
}

.notification {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  background-color: var(--overlay-popover);
  padding: 20px 16px;
}

.control {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-left: 1px solid var(--decorative-theme-white);
  padding-left: 16px;
  margin-left: 16px;
}
