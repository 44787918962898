.container {
  padding: var(--unit_5, 20px);
  display: grid;
  text-decoration: none;
  background-color: white;
  grid-template-columns: 375px 1fr 232px 48px;
  grid-column-gap: var(--unit_2, 8px);
  margin: 0 calc(var(--unit_5, 20px) * -1);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 20px;
    left: 20px;
    height: 1px;
    background-color: var(--gray6_100);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
}

.photo-block {
  position: relative;
  height: 295px;
}

.main-content-block {
  padding: 0 var(--unit_6, 24px);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.publisher-block {
  padding: 0 var(--unit_4, 16px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_1, 4px);
}

.content-container {
  display: grid;
  grid-row-gap: var(--unit_4, 16px);
}

.footer-container {
  margin-top: var(--unit_4, 16px);
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
  /* Скролл должен идти от 5 строк, то есть 5 показываются, а остальные скрываются за скролом */
  max-height: calc(var(--unit_4, 16px) * 5);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.publisher-avatar-wrapper {
  margin-bottom: var(--unit_3, 12px);
}

.more-info-button-wrapper {
  margin-top: var(--unit_5, 20px);
}

.subtitle-wrapper {
  padding-bottom: var(--unit_6, 24px);
}

.title-wrapper {
  padding-bottom: var(--unit_2, 8px);
}

.empty {
  margin-bottom: 0;
}

.close-block {
  display: flex;
  justify-content: flex-end;
}

.transports-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_0, 2px);

  &:empty {
    display: none;
  }
}

@media (max-width: 1279px) {
  .footer-container {
    /* Скролл должен идти от 4 строк, то есть 4 показываются, а остальные скрываются за скролом */
    max-height: calc(var(--unit_4, 16px) * 4);
  }
}
