.note {
  display: flex;
  margin: 16px 20px;
  align-items: flex-start;
  padding: 12px;
  border-radius: 4px;
  background: var(--gray6_100);
}

.note.danger {
  background: var(--surface-negative-default);
}

.icon {
  margin-right: 12px;
}

.cont a {
  text-decoration: none;
  color: var(--accent-main-primary);
}
