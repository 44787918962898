.banner {
  background: #e6f0ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.info {
  margin: 16px 16px 16px 40px;
}

.header {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin: 12px 0;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 12px 0;
}

.button {
  margin: 24px 0;
}
