.container {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.is-visible {
  margin-bottom: 40px;
}
