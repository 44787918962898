.container {
  position: relative;
  z-index: 0;
  margin: 26px 0;
  padding: 26px 0 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 200px;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(91.25deg, #ffe1ff 2.21%, #c1e8fd 65.68%);
  border-radius: 8px;
}

.container::before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
  display: block;
  content: '';
  background-image: url('./assets/left.svg'), url('./assets/right.svg');
  background-repeat: no-repeat, no-repeat;
  background-position:
    left center,
    right center;
}

.description {
  margin: 0 auto;
  padding: 8px 0 16px;
  max-width: 440px;
}

.button {
  margin: 0 auto;
  max-width: 215px;
}

@media screen and (max-width: 1180px) {
  .container::before {
    background-image: url('./assets/smallLeft.svg'), url('./assets/smallRight.svg');
  }
}
