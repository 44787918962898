.container {
  margin: 48px 0;
  overflow: hidden;
  border-radius: 8px;
  background-image:
    url('./assets/mortgage-broker.png'),
    linear-gradient(271deg, #006cfd 1.36%, #00357c 42.7%, #00285e 53.72%, #001c41 65.66%, #000 100.53%);
  background-size: 320px, 100%;
  background-position:
    96% 45%,
    center;
  background-repeat: no-repeat, no-repeat;
}

.wrapper {
  padding: 24px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  cursor: default;
}

.title {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: bold;
  line-height: 100%;
  color: var(--decorative-theme-white);
}

.description {
  margin-bottom: 28px;
}

.button {
  cursor: pointer;
}
