.direction {
  padding: 0 15px 25px;
}

.direction-title {
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  user-select: none;
}

.direction-title:hover {
  color: #0084ff;
}

.direction-meta {
  margin-bottom: 10px;
  font-style: italic;
  line-height: 18px;
  color: #7a7a7a;
}
