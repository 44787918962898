.wrapper {
  display: inline-flex;
  position: relative;
}

.wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
  z-index: 1;
}
