.wrapper {
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.title {
  margin: 4px 0 16px;
  padding-top: 22px;
  font-size: 22px;
  font-weight: 700;
  color: #191919;
}

.moreSuggestionsButtonContainer {
  text-align: center;
}

.card {
  & + & > div {
    border-top: none;
  }
}

.commercialWrapper:not(:last-child) {
  margin-bottom: 10px;
}
