.inlineBlock {
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}

.inlineLabel {
  margin-right: 8px;
  display: inline-block;
  vertical-align: top;
}

div.fromToControl {
  width: 98px;
}
