:root {
  --button-padding: 12px;
}

.columnWrapper {
  padding-bottom: 120px;
  display: flex;
}

.column {
  width: 25%;

  &-item {
    padding: 3px 15px 3px 0;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: #0084ff;
    }
  }
}

.letterTitle {
  margin-top: 35px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.33;
  color: #121212;
}

.endButton {
  position: relative;
  margin-top: 2px;
  margin-left: -12px;
  padding: 3px 28px 5px 12px;
  overflow: hidden;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    top: 8px;
    right: 12px;
    display: block;
    float: right;
    width: 8px;
    height: 8px;
    cursor: pointer;
    content: '';
    background: url('../assets/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
