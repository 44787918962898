.wrapper {
  position: relative;
  z-index: 1;
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: var(--unit_3);
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}
