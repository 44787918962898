.element {
  padding: 5px 12px 5px 8px;
  font-size: 13px;
  line-height: 1.2;
  color: #121212;
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;

  &::placeholder {
    color: var(--decorative-subway-msk-serpukhovskaya);
  }

  &:focus {
    border-color: #121212;
  }

  &:disabled {
    color: var(--decorative-subway-msk-serpukhovskaya);
    border-color: rgba(202, 202, 202, 0.5);
  }
}
