@property --skeleton-color {
  syntax: '<color>';
  inherits: false;
  initial-value: #f3f6ff;
}

.container {
  padding: 16px 40px 40px;
  display: flex;
  flex-direction: column;
  width: 840px;
  gap: 16px;
}

.header {
  display: grid;
  grid-template-columns: 240px auto;
  gap: 24px;
}

.image {
  width: 240px;
  height: 188px;
  background: linear-gradient(300deg, var(--skeleton-color) 40%, white 70%, var(--skeleton-color) 80%);
  border-radius: 4px;
}

.info-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.layout {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.layout-params {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.layout-price {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contacts {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.contacts-contact {
  display: flex;
  gap: 12px;
  align-items: center;
}

.contact-image {
  width: 40px;
  height: 40px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.layout-title {
  width: 140px;
  height: 22px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.layout-square {
  width: 140px;
  height: 30px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.price {
  width: 240px;
  height: 30px;
  background: linear-gradient(300deg, var(--skeleton-color) 10%, white 40%, var(--skeleton-color) 50%);
  border-radius: 4px;
}

.price-per-meter {
  width: 180px;
  height: 22px;
  background: linear-gradient(300deg, var(--skeleton-color) 10%, white 30%, var(--skeleton-color) 40%);
  border-radius: 4px;
}

.contact-title-type {
  width: 80px;
  height: 14px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.contact-title-name {
  width: 120px;
  height: 22px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.contacts-control {
  width: 206px;
  height: 40px;
  background: linear-gradient(300deg, var(--skeleton-color) 50%, white 70%, var(--skeleton-color) 80%);
  border-radius: 4px;
}

.contacts-contact-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.table {
  display: grid;
  width: 100%;
}

.table-header {
  position: sticky;
  top: 0;
  display: grid;
  background: white;
  grid-column: span 5;
  grid-template-columns: subgrid;
}

.header-cell {
  margin-bottom: 8px;
  padding: 12px 0 8px;
  color: #b8c1d6;
  border-bottom: 1px solid #c9d1e5;
}

.offer-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.offer-title-link {
  width: 140px;
  height: 22px;
  background: linear-gradient(300deg, var(--skeleton-color) 50%, white 70%, var(--skeleton-color) 80%);
  border-radius: 4px;
}

.labels {
  width: 80px;
  height: 24px;
  background: linear-gradient(300deg, var(--skeleton-color) 50%, white 70%, var(--skeleton-color) 80%);
  border-radius: 4px;
}

.row {
  padding: 8px 0;
  display: grid;
  min-height: 68px;
  grid-column: span 5;
  grid-template-columns: subgrid;
}

.description-cell {
  width: 120px;
  height: 22px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}

.description-cell:nth-child(2n + 1) {
  background: linear-gradient(300deg, var(--skeleton-color) 50%, white 70%, var(--skeleton-color) 80%);
}

.description-controls {
  display: flex;
  gap: 8px;
  color: var(--skeleton-color);
}

.favorite {
  width: 24px;
  height: 24px;
  background-color: var(--skeleton-color);
  border-radius: 4px;
}
