.highway {
  margin: 2px 0;
  line-height: 18px;
  cursor: pointer;
}

.highway:hover {
  color: #0084ff;
}

.highway-title {
  padding: 3px 0;
  display: block;
}

.button {
  position: relative;
  margin-left: -16px;
  padding-top: 3px;
  padding-right: 30px;
  padding-bottom: 3px;
  line-height: 18px;
}

.button::after {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  width: 15px;
  height: 20px;
  content: '';
  background: url('./assets/close.svg');
  background-repeat: no-repeat;
  background-position: center;
}
