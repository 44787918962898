.container {
  display: flex;
  padding: var(--unit_5);
  gap: var(--unit_6);
  align-items: center;
  border-radius: var(--unit_2);
  background: var(--Solid-Gray50, #f3f6ff);
  margin: var(--unit_6) auto;
  width: 100%;
  max-width: 1336px;
}

@media (max-width: 1336px) {
  .container {
    margin: var(--unit_6) var(--unit_5);
    width: auto;
  }
}

.image {
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
