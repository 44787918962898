.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.slides {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;

  &--noTransform {
    transition: none;
  }
}

.slide {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  cursor: pointer;
}
