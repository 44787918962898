.wrapper {
  margin-top: 8px;
  height: 78px;
  flex-shrink: 0;
  display: flex;
  content-visibility: auto;
}

.overlay {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--overlay-default);
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
