.section {
  margin-top: 45px;
  padding-bottom: 40px;
}

.section-heading {
  max-width: 1376px;
  padding: 0 20px 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.wrapper {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  padding: 0 20px;
}

.card {
  width: 100%;
  min-width: 200px;
  max-width: 270px;
  padding-bottom: 25px;
}

:global(.journal-highlights-carousel_wrapper .journal-highlights-carousel-button-disabled) {
  display: none;
}
