.container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--decorative-theme-dark);
}

.icon {
  display: flex;
  margin-right: 4px;
}

.remoteness {
  color: var(--gray60_100);
}

.remoteness::before {
  content: '・';
  color: var(--decorative-theme-dark);
}
