.container {
  margin: 24px 0;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background:
    url('./assets/cards.png') max(495px, 100%) 0 no-repeat,
    var(--accent-main-primary);
  background-size: contain;
  border-radius: 8px;
}

.description-wrapper {
  margin: 8px 0 16px;
}
