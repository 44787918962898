.gpt-banner {
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  background:
    url('./assets/image.png') max(443px, 100%) 0 no-repeat,
    var(--accent-main-primary);
  background-size: contain;
  padding: var(--unit_6) var(--unit_10);
  padding-right: 43%;
  margin: var(--unit_6) 0;
}

.text {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_2);
  margin-bottom: var(--unit_6);
}
