.title-highlighted {
  background: rgba(43, 135, 219, 0.3);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.star-rating {
  position: relative;
}

.star-rating::before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(100% + var(--unit_0));
  width: 12px;
  height: 12px;
  background: url('./assets/starRating.svg');
}

.subtitle {
  margin-top: 4px;
}

.link {
  text-decoration: none;
}
