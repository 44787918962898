.container {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 12px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray60_100);
  background: var(--gray6_100);
  border-radius: 4px;
}

.container:last-of-type {
  margin-right: 0;
}

.container:hover {
  background-color: var(--gray10_100);
}

.container:focus {
  box-shadow: 0 0 0 1px var(--stroke-control-focused);
}

.button {
  position: relative;
  top: -2px;
  margin-left: 4px;
  padding: 0;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  cursor: pointer;
  background: none;
  border: 0;
}

.button::before,
.button::after {
  position: absolute;
  top: 1px;
  left: 5px;
  width: 1px;
  height: 10px;
  content: ' ';
  background-color: var(--icon-secondary-default);
}

.button::before {
  transform: rotate(45deg);
}
.button::after {
  transform: rotate(-45deg);
}
