.container {
  margin: 40px 0 49px;
}

.title {
  margin-bottom: 16px;
}

.button {
  font-size: 16px;
  line-height: 22px;
  color: var(--accent-main-primary);
  text-decoration: none;
}

.list {
  margin: 25px 0 35px;
  display: flex;
  width: 100%;
}
