.button {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 9px;
  height: 9px;
  pointer-events: none;
  background: var(--accent-negative-primary);
  border: 1px solid var(--decorative-theme-white);
  border-radius: 8px;
}
