@import '@cian/ui-kit/typography/variables.module.css';

.cont {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.inner {
  display: inline-flex;
  align-items: center;
}

.row svg {
  margin-right: 8px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.liquidity-points {
  margin-left: 4px;
  color: var(--accent-warning-primary);
  font-weight: normal;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
}

.bet {
  color: var(--accent-warning-primary);
  font-weight: bold;
  margin-left: 4px;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
}

.icon + .icon {
  margin-left: 4px;
}

.button {
  margin-top: 20px;
}

.button a {
  color: var(--accent-main-primary);
  text-decoration: none;
}

.tip {
  margin-left: 4px;
  cursor: pointer;
  color: var(--icon-secondary-default);
}

.tip:hover {
  color: var(--accent-main-primary);
}
