.banner-border {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.banner-content-wrapper {
  margin-left: 32px;
}

.banner-container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1376px;
}

.banner {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: white;
  display: flex;
  font-family: Lato;
  margin-bottom: 34px;
  padding: 24px 32px 23px;
}

.banner-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.subscription {
  .banner-image {
    background-image: url('./pictures/subscribe.svg');
    height: 182px;
    width: 224px;
  }
}

.empty {
  padding: 0;

  .banner-image {
    background-image: url('./pictures/empty.svg');
    height: 119px;
    width: 98px;
  }

  .banner {
    margin-bottom: 0;
  }
}
