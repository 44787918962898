.container {
  display: flex;
  flex-direction: column;
  gap: var(--unit_4);
  width: 100%;
  max-width: 1376px;
  box-sizing: border-box;
  padding: 0 var(--unit_5);
  margin: 0 auto var(--unit_4);
}

.links {
  display: flex;
  gap: var(--unit_4);
  flex-wrap: wrap;
  overflow: hidden;
  height: var(--unit_7);
}

.links--expanded {
  height: auto;
}

.links-block {
  display: flex;
  gap: var(--unit_4);
}

.counter {
  color: var(--text-primary-disabled);
}

.link {
  display: inline-flex;
  gap: var(--unit_1);
  text-decoration: none;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
}

.full-list-hidden {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  background: transparent;
  color: transparent;

  a {
    color: transparent;
  }
}
