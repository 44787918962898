.container {
  width: 100%;
  max-width: 1376px;
  min-width: 305px;
}

.container.full-width {
  margin: 0 auto var(--unit_8);
  padding: 0 var(--unit_5);
}

.list {
  display: flex;
  border: 1px solid var(--gray20_100);
  border-radius: var(--unit_1);
}
