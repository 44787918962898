.input-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.input-label {
  width: 200px;
  padding-top: 4px;
  padding-right: 30px;
}

.input-label-bold {
  font-weight: bold;
}

.input-max_bet {
  font-weight: bold;
  text-align: right;
  padding-top: 4px;
  flex-grow: 1;
}

.buttons {
  display: flex;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
}

.buttons-right {
  justify-content: flex-end;
}
