.cell {
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
}

.inner {
  position: relative;
}

.day_of_week {
  color: var(--gray60_100);
}

.available {
  cursor: pointer;
  color: var(--decorative-theme-dark);
}

.disabled {
  color: var(--gray20_100);
}

.selected,
.range_start,
.range_end {
  cursor: pointer;
  color: var(--decorative-theme-white);
  background: var(--accent-main-primary);
  border-radius: var(--unit_1);
}

.cell.available:not(.selected):hover,
.cell.available:not(.range_start):hover,
.cell.available:not(.range_end):hover {
  border: 1px solid var(--gray40_100);
  border-radius: var(--unit_1);
}

.in_range {
  cursor: pointer;
  color: var(--decorative-theme-dark);
  background: var(--surface-main-default);
  box-shadow:
    6px 0 0 0 var(--surface-main-default),
    -6px 0 0 0 var(--surface-main-default);
}

.cell.in_range:first-of-type {
  box-shadow: 2px 0 0 0 var(--surface-main-default);
}

.cell.in_range:last-of-type {
  box-shadow: -2px 0 0 0 var(--surface-main-default);
}

.range_start:not(:nth-child(7)) {
  box-shadow: 6px 0 0 0 var(--surface-main-default);
}

.range_end:not(:nth-child(1)) {
  box-shadow: -6px 0 0 0 var(--surface-main-default);
}
/* stylelint-disable selector-max-specificity */
.cell:not(.in_range):not(.range_start) + .range_end::before {
  display: none;
}
