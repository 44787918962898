.root {
  display: flex;
  align-items: center;
}

.link {
  color: var(--accent-main-primary);
  text-decoration: none;
  outline: none;
}

.label {
  position: relative;
  z-index: 1;
  margin-left: 12px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  color: var(--accent-warning-primary);
  background: var(--surface-warning-default);
  border-radius: 4px;
  cursor: pointer;
}

.info {
  z-index: 1;
  margin-left: 4px;
  margin-top: 4px;
  cursor: pointer;
}
