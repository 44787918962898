.container {
  margin: 24px 0;
  padding: 24px;
  display: block;
  text-decoration: none;
  background:
    url('./assets/building.png') 100% 0 no-repeat,
    linear-gradient(91deg, var(--brand-cyan) 0%, #dae5fe 28.6%, var(--brand-pink) 100%);
  background-repeat: no-repeat no-repeat;
  background-size:
    244px auto,
    100%;
  border-radius: 16px;
}

.inner {
  max-width: 70%;
}

.description-wrapper {
  margin: 8px 0 16px;
}
