.container {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 2;
  margin-top: 8px;
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  width: 300px;
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.dropdown-filter {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: max-content 1fr;
  grid-gap: var(--unit_3);
  align-items: center;
}
