.photo {
  min-height: 145px;
  height: 145px;
}

.info {
  padding: var(--unit_2) var(--unit_4) var(--unit_4);
  color: var(--decorative-theme-black);
  height: 100%;
}

.price {
  margin: var(--unit_1) 0;
}

.link {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 241px;
  background-color: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  flex: 1;
  transition:
    transform 0.1s,
    box-shadow 0.1s;
  cursor: pointer;
  overflow: hidden;
}

.link:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-bottom: none;
}
