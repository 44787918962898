.directions {
  padding: 24px 16px;
  display: flex;
  min-height: 200px;
  flex-wrap: wrap;
}

.directions-column {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.preloader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
