.container {
  display: flex;
  margin: 0 0 16px;
  flex-wrap: wrap;
  min-height: 40px;
  height: 40px;
  overflow: hidden;
}

.advantage-container {
  display: flex;
  width: 154px;
  align-items: center;
}

.advantage-container ~ .advantage-container {
  margin-left: 32px;
}

.advantage-icon {
  min-width: 40px;
}

.advantage-text {
  margin-left: 8px;
}
