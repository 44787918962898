.container {
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 360px;
  height: 640px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--gray10_100);
  z-index: 10;

  &.above-modal {
    z-index: 110;
  }
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
