.container {
  margin: 0;
  display: flex;
}

.container + .container {
  margin-left: 12px;
}

@media (max-width: 1279px) {
  .container + .container {
    margin-bottom: 12px;
    margin-left: 0;
  }
}
