.left {
  flex-basis: auto;
  margin-right: 80px;
  grid-area: leftPanel;
}

.right {
  flex: 0 0 544px;
  grid-area: rightPanel;
}

.content {
  display: flex;
  flex-direction: column;
}
