.container {
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--gray6_100);
}

.container > * {
  margin-left: 8px;
}
