/* stylelint-disable-next-line */
:global(#frontend-serp) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.delimiter {
  margin: 0 auto 20px;
  padding: 0 20px;
  width: 100%;
  max-width: 1376px;
}

.delimiter hr {
  margin: 0;
  height: 1px;
  border: 0;
  background: #e4e4e4;
}

.serp {
  flex: 1;
  position: relative;
  margin: 0 auto;
  min-width: 1135px;
  min-height: 160px;
  background-color: #f3f4f6;

  & > :last-child {
    margin-bottom: 0;
  }
}

.serp--light {
  background-color: white;
}

.preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
  margin: auto;
}

.preloadOverlay {
  position: absolute;
  z-index: 10;
  top: 1px;
  right: 0;
  left: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  transition: opacity 0.3s linear;
  will-change: opacity;
}

@media print {
  .serp {
    /* Fix for firefox */
    display: block;
  }
}
