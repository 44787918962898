.banner {
  flex-basis: 25%;
  flex-shrink: 1;
  position: relative;
}

.fixed-width {
  flex-basis: auto;
}

.left-border {
  margin-left: var(--unit_8);
  padding-left: var(--unit_8);
}

.right-border {
  margin-right: var(--unit_8);
  padding-right: var(--unit_8);
}

.left-border::before,
.right-border::before {
  content: '';
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  background: var(--gray10_100);
}

.left-border::before {
  left: 0;
}

.right-border::before {
  right: 0;
}
