.banner {
  all: unset;
  padding: 24px 20px;
  cursor: pointer;
}

.content {
  position: relative;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  height: 200px;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  border-radius: 8px;
  gap: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  all: unset;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: var(--text-primary-default);
}

.description {
  all: unset;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  color: var(--text-primary-default);
}

.label {
  width: 190px;
  height: 32px;
  flex-shrink: 0;
  background: url('./assets/label.png') 50% / cover no-repeat;
}

.house-left {
  position: absolute;
  right: 199px;
  bottom: 0;
  width: 177px;
  height: 174px;
  background: url('./assets/house1.svg');
}

.house-right {
  position: absolute;
  right: 79px;
  bottom: 0;
  width: 175px;
  height: 200px;
  background: url('./assets/house2.svg');
}

.coin {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 135px;
  height: 108px;
  background: url('./assets/coin.svg');
}

.tablet-coin {
  display: none;
  width: 48px;
  height: 46px;
  background: url('./assets/tablet_coin.svg');
}

@media (max-width: 1147px) {
  .house-left,
  .house-right,
  .coin {
    display: none;
  }

  .tablet-coin {
    display: block;
  }

  .header {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.2px;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
  }

  .content {
    flex-direction: row;
    height: auto;
    align-items: center;
  }

  .content {
    padding: 20px 24px 20px 20px;
  }

  .label {
    margin-left: auto;
  }
}
