.mapMenu {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: calc(50% - 130px);
  padding: 0 15px;
  display: flex;
  height: 46px;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;

  &-title {
    padding: 0 10px;
    font-size: 14px;
    line-height: 1.57;
    color: #505152;
  }
}

.mapMenuIcon {
  padding: 5px;
  width: 40px;
  height: 35px;
  cursor: pointer;
  color: #7b7b7b;
  background: none;
  border: none;
  outline-style: none;

  &:hover {
    color: black;
  }

  &--active {
    color: #147de8;

    &:hover {
      color: #147de8;
    }
  }
}
