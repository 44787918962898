.button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
}

.button.button--with-dots button {
  letter-spacing: 1px;
}

.wrapper {
  display: flex;
  overflow: hidden;
  flex: 0 1 auto;
  align-items: center;
}

.wrapper--inline {
  display: inline;
}

.wrapper > div {
  flex: 0 0 auto;
}

.wrapper div {
  white-space: nowrap;
}

.wrapper--big {
  flex: 1 1 auto;
}
