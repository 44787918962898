.container {
  position: relative;
  max-width: 1376px;
  margin: 0 auto 44px;
  width: 100%;
  height: 148px;
  padding: 0 20px;
}

.wrapper {
  display: flex;
  min-width: 0;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 42px 40px;
}

.preview {
  margin-right: 40px;
  width: 64px;
  height: 64px;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
  animation-name: skeleton;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.line {
  display: block;
  width: 100%;
  height: 24px;
  flex: none;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
  animation-name: skeleton;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;

  &:first-child {
    max-width: 400px;
  }

  &:last-child {
    margin-top: auto;
    max-width: 740px;
  }
}

.layout {
  position: absolute;
  margin: 0 -20px;
  top: 0;
  right: 0;
  left: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
}

@keyframes skeleton {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
