.seo-text {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  margin: 4px 0 8px;

  ul li {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    margin: 0;
  }
}
