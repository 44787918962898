.thumbnail {
  width: 123px;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background: var(--gray6_100);
}

.thumbnail svg {
  width: 24px;
  height: 24px;
}

.thumbnail + .thumbnail {
  margin-left: 8px;
}
