.cont {
  position: relative;
  height: 28px;
  margin-bottom: 8px;
  width: 40px;
}

.inner {
  position: absolute;
  width: 40px;
  height: 28px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(10px);
  transition:
    transform 200ms ease-in,
    opacity 200ms ease-in;
}

.inner.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
