.media {
  width: 544px;
  height: 350px;
  display: grid;
  grid-template-columns: 360px 176px;
  grid-template-rows: 171px 171px;
  grid-gap: 8px;
}

.single-row {
  grid-template-rows: 100%;
}
