.container {
  padding: var(--unit_4);
  display: flex;
  text-decoration: none;
  width: 100%;
}

.container:not(:first-child) {
  border-left: 1px solid var(--gray20_100);
}

.icon {
  width: var(--unit_10);
  height: var(--unit_10);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-shrink: 0;
}

.icon.villages {
  background-image: url('./assets/villages.svg');
}

.icon.builders {
  background-image: url('./assets/builders.svg');
}

.text {
  margin: 0 var(--unit_2) 0 var(--unit_5);
}

.title {
  margin-bottom: var(--unit_1);
}

.arrow {
  margin-left: auto;
  align-self: center;
}
