.root {
  display: flex;
  align-items: center;
}

.rating {
  display: flex;
  align-items: center;
}

.rating > *:nth-child(2) {
  margin-left: var(--unit_1);
}

.reviews-count {
  margin-left: var(--unit_1);
}

.reviews-count.dot {
  position: relative;
  margin-left: var(--unit_3);
}

.reviews-count.dot::before {
  position: absolute;
  top: 50%;
  right: calc(100% + 4px);
  width: 3px;
  height: 3px;
  content: '';
  border-radius: 50%;
  background: #707a95;
  transform: translateY(-50%);
}

.reviews-count span {
  color: #707a95;
}
