.container {
  display: flex;
  align-items: center;
}

.container > button {
  font-weight: bold;
  padding: 0 10px;
  position: relative;
}

.container > button + button {
  margin-left: 1px;
}

.container > button + button::before {
  content: '';
  position: absolute;
  left: -2px;
  width: 1px;
  height: 12px;
  top: calc(50% - 6px);
  background: var(--gray20_100);
}
