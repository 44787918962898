.container {
  margin-bottom: -12px;
}

.container--line {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.container--line .specialTags {
  flex: 0 0 auto;
}

.container--line .buttons {
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.specialTags {
  margin-right: 10px;
  display: inline;
}

.tags {
  display: inline;
}

.tag {
  margin-right: 12px;
  margin-bottom: 12px;
  display: inline-block;
}

.buttons {
  display: inline;
  white-space: nowrap;

  & > :not(:last-child) {
    margin-right: 10px;
  }
}
