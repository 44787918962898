.container {
  display: flex;
  max-width: 1376px;
  margin: 0 auto 24px;
  width: 100%;
  background: var(--gray6_100);
  border-radius: 4px;
  height: 342px;
  padding: 24px 0 24px 24px;
}

.builder {
  display: flex;
  flex-direction: column;
  flex: none;
  width: 220px;
  margin-right: 16px;
}

.carousel {
  position: relative;
  margin-right: 24px;
  margin-left: auto;
  width: 100%;
  max-width: 1060px;
}

/* prettier-ignore */
:global(.newbuilding-promo-builder-offers-carousel_wrapper
    .swiper-container:not(.swiper-container-initialized)
    .swiper-slide) {
  width: 100%;
  max-width: 253px;
  margin-right: 16px;
}

:global(.newbuilding-promo-builder-offers-carousel-button-disabled) {
  display: none;
}

:global(.carousel-offers-fewed .swiper-wrapper) {
  justify-content: flex-end;
}

:global(.carousel-offers-fewed .newbuilding-promo-builder-offers-carousel_wrapper .swiper-slide:last-child) {
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: 0 !important;
}
