.section {
  margin: 0 auto 34px;
  padding: 0 20px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  align-items: center;
}

.left-column {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.right-column {
  flex-shrink: 0;
  display: flex;
}

.right-column a {
  margin-left: 10px;
}
