.cont {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  min-height: 0;
  border-radius: 4px;
  overflow: hidden;
}

.cont :global(.slick-slide) svg,
.cont.cont--empty svg {
  width: 40px;
  height: 40px;
}

.cont :global(.slick-slide) img {
  background-color: white;
}
