.map {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mapContainer {
  width: 100%;
  height: calc(100% - 40px);
}

.deleteMenu {
  position: absolute;
  z-index: 1;
  padding: 17px 21px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: #121212;
  background-color: white;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

  &::before {
    position: absolute;
    z-index: -1;
    top: -6px;
    left: 17px;
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    background-color: white;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: #e0f0ff;

    &::before {
      background-color: #e0f0ff;
    }
  }
}

.preloaderWrapper {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  margin: 0 auto;
  align-self: center;
}

.canvasContainer {
  position: absolute;
  top: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 80px);
}

.warning {
  position: absolute;
  left: 50%;
  bottom: 90px;
  margin-left: -170px;
  padding: 12px 0;
  width: 340px;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}

.warning--inactive {
  visibility: hidden;
  transition: visibility 0.25s;
  animation: warning-pop-out 0.25s forwards;
}

.warning:not(.warning--inactive) {
  visibility: visible;
  animation: warning-pop-in 0.2s forwards;
}

@keyframes warning-pop-in {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes warning-pop-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(150%);
  }
}

@media (max-height: 660px) {
  .warning {
    bottom: 40%;
  }
}
