.popup {
  position: relative;
  padding: 24px 30px 40px;

  &-triggers {
    display: flex;
    justify-content: space-between;
    margin: 28px 40px;
  }

  &-actions {
    & > :not(:last-child) {
      margin-right: 8px;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}
