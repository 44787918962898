.wrapper {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.container {
  margin-top: -1px;
  padding: 24px 30px 32px;
  background-color: white;
  border: 1px solid #e4e4e4;
}

.seo-block {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #121212;
}

.link {
  margin: 0 16px 4px 0;
  display: inline-block;
  font-size: 14px;
  line-height: 1.29;
  text-decoration: none;
  color: #2b87db;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: #256ba5;
  }
}
