.location-switcher {
  padding: 0 32px;
  max-height: calc(100vh - 120px);
  background-color: white;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
