.content {
  max-width: 300px;
}

.text {
  margin-top: 4px;
  margin-bottom: 12px;
}

.link {
  text-decoration: none;
  color: var(--accent-main-primary);
}

.link:visited {
  color: var(--accent-main-primary);
}
