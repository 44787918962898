.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages-list {
  display: flex;
  padding: 0;
  margin: 0 8px;
  list-style: none;
}

.page + .page {
  margin-left: 8px;
}
