.wrapper {
  margin: 0 auto;
  padding: var(--unit_6) 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.title-container {
  margin-bottom: var(--unit_6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: var(--unit_4);
}
