.container {
  padding: 16px;
  overflow: hidden;
  background: white;
  border: 1px solid #c9d1e5;
  border-radius: 8px;
}

.image {
  position: relative;
  overflow: hidden;
  height: 188px;
  border: 1px solid #f3f6ff;
  border-radius: 4px;
}

.description {
  padding: 12px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
