.container {
  width: 100%;
  max-width: 1376px;
  margin: 0 auto;
}

.links {
  margin-top: 16px;
}

.link {
  display: block;
  color: var(--gray60_100);
  text-decoration: none;
  margin-bottom: 4px;
}

.link:hover {
  color: var(--accent-main-primary);
}
