.container {
  width: 100%;
}

.table {
  overflow: hidden auto;
  width: 100%;
  /* Высота экрана минус отступы и контент модального окна */
  max-height: calc(100vh - 380px);
}

.table-header {
  position: relative;
  padding: 0 40px;
  display: grid;
  width: 100%;
  background: white;
  grid-template-columns: auto 120px 112px 120px 60px;
  column-gap: 20px;
}

.divider {
  position: absolute;
  right: 40px;
  left: 40px;
  bottom: 0;
  border-bottom: 1px solid #c9d1e5;
}

.header-cell {
  padding: 12px 0 8px;
  color: #b8c1d6;
}

.offers-link-block {
  padding: 8px 40px 28px;
}

.offers-link {
  padding: 10px 0;
  color: var(--accent-main-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: var(--fontSize_16px);
}
