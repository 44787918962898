.underground-icon,
.underground-icon--regions {
  margin-right: 8px;
  display: inline-flex;
  vertical-align: middle;
}

.underground-icon--regions::after {
  margin: 4px;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  background-color: currentColor;
  border-radius: 8px;
}
