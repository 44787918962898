.container {
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
