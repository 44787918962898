.item {
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  padding: 8px 12px;
  width: 100%;
}

.label {
  flex: 1;
}

.selected-indicator {
  padding-right: 2px;
  flex: 0 0 auto;
}
