.header {
  display: flex;
}

.label {
  margin-right: 10px;
  display: inline-block;
  line-height: 28px;
  vertical-align: top;
  user-select: none;
  color: #121212;
}

.buttons {
  margin-left: auto;
}

.buttons button:first-child {
  margin-right: 10px;
}

.minutes {
  margin-right: 8px;
  max-width: 60px;
}

.minutes-wrapper {
  margin-left: 30px;
  line-height: 28px;
}

.travel-type-select-wrapper {
  width: 130px;
}
