.container {
  display: block;
  border: 1px solid var(--black_10);
  border-radius: 4px;
  text-decoration: none;
  width: calc(100% / 5 - 16px);
  margin-right: 16px;
}

.container:last-child {
  margin-right: 0;
}

.content {
  padding: 16px;
  box-sizing: border-box;
}

.image {
  margin: 12px auto 0;
}

.title,
.price,
.description {
  margin-bottom: 4px;
}

.locationBlock {
  margin-top: 12px;
  display: flex;
  width: 100%;
}

.location,
.time {
  display: flex;
  min-width: 0;
  align-items: center;
}

.locationIcon,
.timeIcon {
  display: flex;
}

.locationText,
.timeText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.location,
.title,
.address {
  overflow: hidden;
  text-overflow: ellipsis;
}

.location,
.locationIcon,
.timeIcon {
  margin-right: 8px;
}

.address,
.timeText {
  color: var(--gray60_100);
}
