.container {
  position: relative;
  padding: var(--unit_6) var(--unit_10);
  height: 186px;
  border-radius: var(--unit_4);
  margin: var(--unit_6) 0;
  overflow: hidden;
}

.content {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--unit_2);
  height: 100%;

  & > a {
    margin-top: auto;
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  width: 749px;
  height: 100%;
  object-fit: contain;
}
