.container {
  display: flex;
  flex-direction: column;
  padding: var(--unit_4);
  align-items: center;
  border-radius: var(--unit_3);
  border: 1px solid var(--Stroke-Border-Default, #c9d1e5);
  margin: var(--unit_6) auto;
  width: 100%;
  max-width: 1336px;

  & > :nth-child(1) {
    margin-bottom: var(--unit_1);
  }

  & > :nth-child(2) {
    margin-bottom: var(--unit_2);
  }

  & > :nth-child(3) {
    margin-bottom: var(--unit_1);
  }
}

@media (max-width: 1336px) {
  .container {
    margin: var(--unit_6) var(--unit_5);
    width: auto;
  }
}

.image {
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
