.container {
  padding: 0 40px;
  display: grid;
  grid-template-columns: 240px auto;
  gap: 24px;
}

.image {
  position: relative;
  overflow: hidden;
  width: 240px;
  height: 188px;
  border: 1px solid #c9d1e5;
  border-radius: 8px;
}

.tour {
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: flex;
  width: 28px;
  height: 28px;
  background-color: #e6f0ff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.info-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.layout {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.layout-params {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.layout-price {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #707a95;
}

.contacts {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
}

.builder {
  display: flex;
  gap: 12px;
  align-items: center;
}

.contact-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
