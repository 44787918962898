.container {
  margin-top: var(--unit_1);
  padding: var(--unit_3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  text-decoration: none;
  color: var(--accent-main-primary);
  border-top: 1px solid var(--gray10_100);
}

.container:hover {
  color: var(--control-main-primary-hovered);
}

.text {
  margin-right: var(--unit_2);
}
