.kinescope-player {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(1.5);
}

.kinescope-player--visible {
  opacity: 1;
}
