.wrapper {
  margin: 0 auto 16px;
  padding: 24px 20px 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.wrapper_redesign {
  padding: 20px 20px 0;
}
