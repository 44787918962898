.container {
  position: absolute;
  z-index: 1;
  left: 16px;
  bottom: 16px;
}

.feature {
  padding: 4px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
