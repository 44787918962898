.link {
  background: var(--decorative-theme-white);
  border-radius: 4px;
  border: 1px solid var(--gray20_100);
  overflow: hidden;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.image {
  display: block;
  width: 100%;
  aspect-ratio: 1.52;
}

.title-wrapper {
  display: block;
  padding: 16px;
}

/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
.title {
  height: 96px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
