.container {
  padding: 8px 40px 40px;
}

.consultant-img {
  margin-bottom: 24px;
  width: 100px;
  height: 80px;
  background-image: url('./assets/consultant.png');
  background-size: contain;
}

.description {
  margin-top: 8px;
  margin-bottom: 30px;
}

.form {
  display: inline-flex;
  flex-direction: column;
}

.phone-input {
  margin-top: 6px;
  margin-bottom: 14px;
}

.btn-group > *:not(:last-child) {
  margin-right: 12px;
}

.private-notes {
  margin-top: 16px;
}
