.underground {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

.underground-station {
  margin-right: 12px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.underground-station > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
