.wrapper {
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1376px;
}

.container {
  position: relative;
  padding: 25px;
  padding-bottom: 20px;
  background-color: white;
  border: 1px solid #e4e4e4;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  font-size: 28px;
  cursor: pointer;
  color: #929294;
  background: none;
  border: none;

  &:hover {
    color: #666668;
  }
}

.header {
  margin: 0;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}

.text {
  margin: 0;
  margin-bottom: 18px;
  font-size: 16px;
}

@media (min-width: 1025px) {
  .wrapper {
    display: none;
  }
}
