/* stylelint-disable cian/no-custom-colors */
.select-popup {
  z-index: 10;
  overflow-y: auto;
  max-height: 400px;
  background: white;
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
}
