.content-container {
  padding: var(--unit_6) var(--unit_10) 0;
  max-height: 100%;
  min-height: 482px;
  position: relative;
}

.preloaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* stylelint-disable-next-line */
  bottom: 0;
  background: white;
  z-index: 99;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--unit_6);
}

.error-image {
  background-image: url('./assets/image.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 186px;
  height: 200px;
}

.error-title {
  margin-bottom: var(--unit_4);
}

.inner {
  max-height: 100%;
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 100%;
  column-gap: var(--unit_4);
}

.list {
  overflow-x: hidden;
  /* stylelint-disable-next-line */
  overflow-y: scroll;
  padding-bottom: var(--unit_6);
  padding-left: var(--unit_0);
}

.map {
  position: relative;
  margin-bottom: var(--unit_6);
}
