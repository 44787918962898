.background {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
  justify-content: center;
  align-items: center;
}

.icon_container {
  width: 16px;
  height: 16px;
}

.icon {
  display: flex;
}

.icon,
.icon img {
  width: 100%;
  height: 100%;
}

.icon img {
  display: flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  vertical-align: top;
  pointer-events: none;
}

.click_area {
  position: absolute;
  z-index: 1;
  top: 50%;
  margin-top: -20px;
  right: -20px;
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  pointer-events: all;
}

.click_area--left {
  right: auto;
  left: -20px;
}
